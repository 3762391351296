import { useQuery } from '@tanstack/react-query';
import { assetActions } from 'actions/asset';

const useFetchInspectionRecord = (id: number, wasAssetHubRefreshedClicked: boolean) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['inspectionRecords', id, wasAssetHubRefreshedClicked], 
    queryFn: async () => {
      const result = await assetActions().getInspectionRecords(id, wasAssetHubRefreshedClicked);
      return result || []; 
    },
    gcTime: 60 * 60 * 1000, 
    staleTime: 30 * 60 * 1000, 
  });

  const inspectionsAvailable = !!data && data.length > 0;
  const inspectionRecords = data || [];

  return { inspectionsAvailable, inspectionRecords, isLoading, isError };
};

export default useFetchInspectionRecord;