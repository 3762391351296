import dayjs from 'dayjs';
import { get } from 'utils/request';
import { getRefreshDataHeader } from "../utils/getRefreshDataHeader";
import { capitalizeFirstChar, formatCurrency } from '../utils/converter';

export interface SchemeInfo {
  id: number,
  parameterSetId: number,
  year: number,
  sectionLabel: string,
  startChainage: number,
  endSectionLabel: string,
  endChainage: number,
  description: string,
  xsp: string,
  length: number,
  schemeType: string,
  averageCI: number,
  ratingValue: number,
  inefficientLength: number,
  efficiency: number,
  agreed: boolean,
  funded: boolean,
  cIPriority: number,
  creationDate: string,
  contributors: string,
  source: string,
  vMScore: number,
  vMInspectionDate: string,
  vMComments: string,
  area: number,
  missingInventory: boolean,
  Cost: string,
  IsChildScheme: string,
}

export interface TreatmentInfo {
  id: number,
  parameterSetId: number,
  schemeId: number,
  order: number,
  cost: number,
  life: number,
  lanes: number,
  implementationYear: number,
  economicRanking: number,
  funded: boolean,
  creationDate: string;
}

export interface FwpInfo {
  id: number,
  schemeId: number,
  schemeType: string,
  implementationYear: number,
  sectionLabel: string,
  startChainage: number,
  endChainage: number,
  xsp: string,
  length: number,
  treatmentDescription: string,
  life: number,
  lanesTreated: number,
  cost: string,
  initialTreatment: boolean,
  inflatedCost: boolean,
  treatmentPlanId: number,
}

const schemeActions = () => {

  const getProgrammedSchemeDetails = async (data: { id: any }, refreshData: boolean = false): Promise<SchemeInfo> => {
    const response = await get<SchemeInfo>(`schemes/programmed/${data?.id}`, getRefreshDataHeader(refreshData));
    const responseData = response?.data;
    
    if (responseData.creationDate) responseData.creationDate = dayjs(responseData?.creationDate).format('YYYY-MM-DD');
    if (responseData.vMInspectionDate) responseData.vMInspectionDate = dayjs(responseData?.vMInspectionDate).format('YYYY-MM-DD');
    if (responseData.Cost) {
      responseData.Cost = formatCurrency(responseData.Cost);
    }
    if (responseData.IsChildScheme !== undefined && responseData.IsChildScheme !== null) {
      responseData.IsChildScheme = capitalizeFirstChar(String(responseData.IsChildScheme)); 
    }

    (Object.keys(responseData) as (keyof SchemeInfo)[]).forEach((key) => {
      if (responseData[key] === null) {
        (responseData as any)[key] = 'N/A';
      }
    });
    return responseData;
  }

  const getSchemeDetails = async (data: { id: any }, refreshData: boolean = false): Promise<SchemeInfo> => {
    const response = await get<SchemeInfo>(`schemes/${data?.id}`, getRefreshDataHeader(refreshData));
    const responseData = response?.data;
    if (responseData.creationDate) responseData.creationDate = dayjs(responseData?.creationDate).format('YYYY-MM-DD');
    if (responseData.vMInspectionDate) responseData.vMInspectionDate = dayjs(responseData?.vMInspectionDate).format('YYYY-MM-DD');
    if (responseData.Cost) responseData.Cost = formatCurrency(responseData.Cost);

    (Object.keys(responseData) as (keyof SchemeInfo)[]).forEach((key) => {
      if (responseData[key] === null) {
        (responseData as any)[key] = 'N/A';
      }
    });
    return responseData;
  }

  const getFwpDetails = async (fwpId: string, refreshData: boolean = false): Promise<FwpInfo> => {
    const response = await get<FwpInfo>(`schemes/fwps/${fwpId}`, getRefreshDataHeader(refreshData));
    const responseData = response?.data;
    if (responseData.cost) {
      responseData.cost = formatCurrency(responseData.cost);
    }

    (Object.keys(responseData) as (keyof FwpInfo)[]).forEach((key) => {
      if (responseData[key] === null) {
        (responseData as any)[key] = 'N/A';
      }
    });

    return responseData;
  }

  const getTreatmentDetails = async (treatmentId: string, refreshData: boolean = false): Promise<TreatmentInfo> => {
    const response = await get<TreatmentInfo>(`schemes/treatments/${treatmentId}`, getRefreshDataHeader(refreshData));
    const responseData = response.data;
    if (responseData.creationDate) responseData.creationDate = dayjs(responseData?.creationDate).format('YYYY-MM-DD HH:mm');

    (Object.keys(responseData) as (keyof TreatmentInfo)[]).forEach((key) => {
      if (responseData[key] === null) {
        (responseData as any)[key] = 'N/A';
      }
    });

    return responseData;
  }

  return {
    getSchemeDetails,
    getFwpDetails,
    getTreatmentDetails,
    getProgrammedSchemeDetails
  };
};

export { schemeActions };
