import { get } from 'utils/request';
import { AssetInfo } from "@actions/asset";
import { getRefreshDataHeader } from "../utils/getRefreshDataHeader";

export interface Layers {
  name: string;
  year: number;
  key: number;
  subCategories: [];
  enabled: boolean;
  endPoint: string;
}

export interface Categories {
  name: string;
  layers: Layers[];
}

export interface LegendResponse {
  externalSources: [];
  layerCategories: Categories[];
}

const useLegendActions = () => {
  const getLegend = async (
    refreshLegendToolbar: boolean = false,
    options: { layerKeys?: string[] } = {}
  ): Promise<LegendResponse> => {
    const { layerKeys } = options;

    const headers = getRefreshDataHeader(refreshLegendToolbar);

    const config = {
      ...headers,
      ...(layerKeys && layerKeys.length > 0 && {
        params: { layerKeys: layerKeys.join(',') }, 
      }),
    };

    try {
      const response = await get<LegendResponse>('visualiser/legend', config);
      return response.data;
    } catch (error) {
      console.error("Failed to fetch legend data:", error);
      throw error;
    }
  };

  return {
    getLegend,
  };
};

export { useLegendActions };