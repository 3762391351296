import React, { useState, useEffect } from 'react';
import ChartHub from './ChartHub';
import InspectionsSummaryReport from './InspectionsSummaryReport';
import ImageHub from './ImageHub';
import ToggleSwitchWithText from 'components/ToggleSwitchWithText';
import useFetchInspectionRecord from 'customHooks/useFetchInspectionRecord';

type AssetHubOptionsProps = {
  FAKE_BRIDGES: Array<{ name: string; src: string }>,
  id: number,
  wasAssetHubRefreshedClicked: boolean,
  wasAssetHubRefreshLoading: boolean,
};

const ASSET_HUB_OPTIONS = [
  { value: 'Report', label: 'Report' },
  { value: 'Charts', label: 'Charts' },
  // { value: 'Images', label: 'Images' },
];

const AssetHubOptions: React.FC<AssetHubOptionsProps> = ({
  FAKE_BRIDGES,
  id,
  wasAssetHubRefreshedClicked,
  wasAssetHubRefreshLoading
}) => {
  const [selectedAssetHub, setSelectedAssetHub] = useState<string>(ASSET_HUB_OPTIONS[0].value);
  const [requestType, setRequestType] = useState<string>('inspections');
  const {inspectionsAvailable} = useFetchInspectionRecord(id, wasAssetHubRefreshedClicked);
  const [options, setOptions] = useState(ASSET_HUB_OPTIONS);

  useEffect(() => {

    if (!inspectionsAvailable) {
      setOptions(ASSET_HUB_OPTIONS.filter(opt => opt.value !== 'Report'));
      if (selectedAssetHub === 'Report') {
        setSelectedAssetHub('Charts');
      }
    } else {
      setOptions(ASSET_HUB_OPTIONS);
    }
  }, [inspectionsAvailable, selectedAssetHub]);

  const onChangeAssetHub = (sp: string) => {
    setSelectedAssetHub(sp);
  };

  const onChangeRequestType = (sp: string) => {
    setRequestType(sp);
  };

  const filteredOptions = inspectionsAvailable
? ASSET_HUB_OPTIONS
: [ASSET_HUB_OPTIONS[1]];

  const containerClass = inspectionsAvailable
  ? 'xa_asset_hub--options_container'
  : 'xa_asset_hub--options_container hide-summary';

  return (
    <div className={containerClass}>
      <div className="xa_asset_hub--options-select">
        <select name="assetHub" id="assetHub" onChange={(e) => onChangeRequestType(e.target.value)}>
          <option value="inspections">Inspection Breakdown</option>
          <option value="treatments">Treatment Breakdown</option>
        </select>
        <ToggleSwitchWithText
          onChange={onChangeAssetHub}
          options={filteredOptions}
          selectedValue={selectedAssetHub}
          name="change_assetHub"
        />
      </div>
      {selectedAssetHub === "Charts" && (
        <ChartHub
        id={id}
        requestType={requestType}
        wasAssetHubRefreshedClicked={wasAssetHubRefreshedClicked}
        wasAssetHubRefreshLoading={wasAssetHubRefreshLoading}
        />
      )}
      {selectedAssetHub === "Report" && (
        <InspectionsSummaryReport assetId={id} />
      )}
      {selectedAssetHub === "Images" && (
        <ImageHub images={FAKE_BRIDGES} />
      )}
    </div>
  );
};

export default AssetHubOptions;
