import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar, BarDatum } from '@nivo/bar';
import Modal from 'react-modal';
import { useState, useEffect, ReactNode } from 'react';
import { MdClose } from 'react-icons/md';
import Loader from 'components/Loader';
import { useUserActions } from 'actions/user';
import { StatisticsResponse } from '../../actions/statistics';
import Draggable from 'react-draggable';
import { FiRefreshCw } from "react-icons/fi";
import { assetActions } from 'actions/asset';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export interface DataChart {
  [x: string]: ReactNode;
  id: string;
  value: string;
  label: string;
}

interface Props {
  isOpen: boolean;
  closeModal: (title: string) => void;
}

const InspectionsToolkit: React.FC<Props> = ({ isOpen = false, closeModal }) => {
  const [theme, setTheme] = useState('');
  const userActions = useUserActions();
  const queryClient = useQueryClient();
  const [isPieChart, setIsPieChart] = useState<boolean>(true);
  const [isModalMinimized] = useState<boolean>(false);

 
  const { data: groupStatus, isLoading, refetch } = useQuery<StatisticsResponse>({
    queryKey: ['inspectionGraphData'],
    queryFn: () => assetActions().getInspectionGraphData(),
    enabled: isOpen, 
  });


  useEffect(() => {
    setTheme(userActions.getUserTheme());
  }, []);

 
  const dataChartAdapter = (stats: StatisticsResponse | undefined): DataChart[] => {
    const data: DataChart[] = [];
    if ((stats?.values ?? []).length > 0) {
      stats?.values.forEach((group) => {
        data.push({
          id: group.key ?? group.label,
          value: group.value.toString(),
          label: group.label,
        });
      });
    }
    return data;
  };

  const dataBarChartAdapter = (stats: StatisticsResponse | undefined): BarDatum[] => {
    const data: BarDatum[] = [];
    const obj = {} as BarDatum;
    if ((stats?.values ?? []).length > 0) {
        stats?.values.forEach((group) => {
        obj[group.key ?? group.label] = group.value?.toString();
      });
      data.push(obj);
    }
    return data;
  };

  const barChartKeysAdapter = (stats: StatisticsResponse | undefined): string[] => {
    const data: string[] = [];
    if ((stats?.values ?? []).length > 0) {
        stats?.values.forEach((group) => {
        data.push((group.key ?? group.label)?.toString());
      });
    }
    return data;
  };

  const barChartLabelsAdapter = (stats: StatisticsResponse | undefined): Map<string, string> => {
    const data = new Map<string, string>();
    if ((stats?.values ?? []).length > 0) {
        stats?.values.forEach((group) => {
        const lkey = group.key ?? group.label;
        data.set(`${lkey}`, `${group.label}`);
      });
    }
    return data;
  };

  // Derived chart data
  const dataChart = dataChartAdapter(groupStatus);
  const dataBarChart = dataBarChartAdapter(groupStatus);
  const barChartKeys = barChartKeysAdapter(groupStatus);
  const barChartLabels = barChartLabelsAdapter(groupStatus);

  const toggleChartType = () => setIsPieChart(prev => !prev);

  const handleRefresh = async () => {
    await refetch();
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => closeModal('Inspections Toolkit')}
      bodyOpenClassName={null}
      portalClassName="react-modal-container"
      overlayClassName="react-modal-overlay"
      className="react-modal-wrapper"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <Draggable
        enableUserSelectHack={false}
        handle=".modal-header"
        bounds="body"
      >
        <div className={`react-modal top-left${isModalMinimized ? ' minimized' : ''}`}>
          <div className="modal-header">
            <div className="modal-title-container" style={{ textTransform: "uppercase" }}>
              Inspections Toolkit
              <FiRefreshCw
                className={isLoading ? "modal-refresh-button rotating" : "modal-refresh-button"}
                onClick={handleRefresh}
              >
                Refresh
              </FiRefreshCw>
            </div>
            <div className="modal-close" onClick={() => closeModal('Inspections Toolkit')}>
              <MdClose />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
            <span style={{ position: "relative", top: "70px", fontWeight: "bold" }}>New Inventory</span>
          </div>
          <div className="modal-content">
            {isLoading && <Loader />}
            <div className='xa-chart'>
              {isPieChart && (
                <ResponsivePie
                  valueFormat=" >-,~f"
                  tooltip={({ datum }) => (
                    <span style={{ padding: 12, color: datum.color, background: '#222222' }}>
                      {(datum.data as DataChart)?.customLabel}: {datum.formattedValue}
                    </span>
                  )}
                  key={`nivo-pie-${theme}`}
                  data={dataChart ?? []}
                  margin={{ top: 80, right: 200, bottom: 80, left: 80 }}
                  innerRadius={0.5}
                  padAngle={0.7}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor={'var(--xa-text)'}
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 100]] }}
                  defs={[
                    {
                      id: 'lines',
                      type: 'patternLines',
                      background: 'inherit',
                      color: 'var(--xa-text)',
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10
                    }
                  ]}
                  legends={[
                    {
                      anchor: 'right',
                      direction: 'column',
                      justify: false,
                      translateX: 135,
                      itemsSpacing: 5,
                      itemWidth: 80,
                      itemHeight: 15,
                      itemTextColor: 'var(--xa-text)',
                      itemDirection: 'left-to-right',
                      itemOpacity: 1,
                      symbolSize: 18,
                      symbolShape: 'circle',
                    }
                  ]}
                  theme={{
                    tooltip: {
                      container: {
                        background: theme === 'dark' ? 'var(--xa-black)' : 'var(--xa-white)',
                      },
                    },
                  }}
                />
              )}
              {!isPieChart && (
                <ResponsiveBar
                  valueFormat=" >-,~f"
                  tooltip={({ id, color, formattedValue }) => (
                    <span style={{ padding: 12, color, background: '#222222' }}>
                      {barChartLabels?.get(`${id}`)}: {formattedValue}
                    </span>
                  )}
                  key={`nivo-bar-${theme}`}
                  groupMode="grouped"
                  keys={barChartKeys}
                  data={dataBarChart}
                  margin={{ top: 80, right: 180, bottom: 80, left: 80 }}
                  borderWidth={1}
                  borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                  defs={[
                    {
                      id: 'lines',
                      type: 'patternLines',
                      background: 'inherit',
                      color: 'var(--xa-text)',
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10
                    }
                  ]}
                  legends={[
                    {
                      dataFrom: "keys",
                      anchor: 'right',
                      direction: 'column',
                      justify: false,
                      translateX: 135,
                      itemsSpacing: 5,
                      itemWidth: 80,
                      itemHeight: 15,
                      itemTextColor: 'var(--xa-text)',
                      itemDirection: 'left-to-right',
                      itemOpacity: 1,
                      symbolSize: 18,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemBackground: theme === 'dark' ? 'rgba(var(--xa-white-rgb), 0.2)' : 'rgba(var(--xa-black-rgb), 0.03)',
                            itemOpacity: 1
                          }
                        }
                      ]
                    }
                  ]}
                  theme={{
                    labels: {
                      text: {
                        fill: theme === 'dark' ? 'var(--xa-white)' : 'var(--xa-black)',
                      },
                    },
                    tooltip: {
                      container: {
                        background: theme === 'dark' ? 'var(--xa-black)' : 'var(--xa-white)',
                      },
                    },
                  }}
                />
              )}
            </div>
          </div>
          <div className="modal-footer">
            <form className="custom-switch">
              <input
                type="radio"
                id="switch_left"
                name="switchToggle"
                value="Pie"
                onChange={toggleChartType}
                checked={isPieChart}
              />
              <label htmlFor="switch_left">Pie</label>
              <input
                type="radio"
                id="switch_right"
                name="switchToggle"
                value="Bar"
                onChange={toggleChartType}
                checked={!isPieChart}
              />
              <label htmlFor="switch_right">Bar</label>
            </form>
          </div>
        </div>
      </Draggable>
    </Modal>
  );
};

export default InspectionsToolkit;