import React, { useState, useEffect, useRef } from "react";
//@ts-ignore
import HorizontalTimeline from "react-horizontal-timeline";
import { useQuery } from '@tanstack/react-query';
import AttributeRow from "../../attributeRow";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import EmptyRequestDisplay from "../AssetHubNoData";
import Loader from "components/Loader";
import { assetActions } from "actions/asset";
import useFetchInspectionRecord from "customHooks/useFetchInspectionRecord";
import {capitalizeFirstChar, formatCurrency} from "../../../../utils/converter";

interface Observation {
  id: number;
  defect: string;
  conditionCode: string;
  conditionScore: number;
  workCost: number;
  comments: string;
  priority: string;
  action: string;
  suggestedTreatment: string | null;
}

interface MergedInspection {
  id: number;
  inspectionDate: string;
  inspectionType: string;
  inspector: string;
  comments: string;
  signedOff: boolean;
  averageScore: number;
  totalCosts: number;
  observations: Observation[];
}

interface InspectionsSummaryReportProps {
  assetId: number;
}

const COLLAPSED_HEIGHT = 380;
const PAGE_SIZE = 1;

const InspectionsSummaryReport: React.FC<InspectionsSummaryReportProps> = ({ assetId }) => {
  const [mergedInspections, setMergedInspections] = useState<MergedInspection[]>([]);
  const [loading, setLoading] = useState(true); // Local loading state
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [overlayOpen, setOverlayOpen] = useState<boolean>(false);
  const [containerHeight, setContainerHeight] = useState<number>(COLLAPSED_HEIGHT);
  const contentRef = useRef<HTMLDivElement>(null);
  // const { inspectionRecords } = useFetchInspectionRecord(assetId, false); 
  const [closingOverlay, setClosingOverlay] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { inspectionRecords, isLoading: recordsLoading, isError: recordsError } = useFetchInspectionRecord(assetId, false);

  const { data: mergedData, isLoading: reportsLoading, isError: reportsError } = useQuery({
    queryKey: ['inspectionReports', assetId, inspectionRecords],
    queryFn: async () => {
      if (!inspectionRecords || inspectionRecords.length === 0) {
        return [];
      }

      const mergedData: MergedInspection[] = await Promise.all(
        inspectionRecords.map(async (record) => {
          const reportResponse = await assetActions().getInspectionReport(Number(assetId), Number(record.id), false);
          const reportData = Array.isArray(reportResponse) ? reportResponse[0] : reportResponse;
          if (!reportData) {
            throw new Error(`Report data for record ${record.id} is undefined`);
          }
          return {
            id: Number(record.id),
            inspectionDate: record.inspectionDate,
            inspectionType: record.inspectionType,
            inspector: record.inspector,
            comments: record.comments,
            signedOff: record.signedOff === "true" || record.signedOff === true,
            averageScore: reportData.averageScore,
            totalCosts: reportData.totalCosts,
            observations: reportData.observations,
          };
        })
      );
      return mergedData.reverse(); 
    },
    enabled: !!inspectionRecords && inspectionRecords.length > 0, 
    staleTime: 30 * 60 * 1000,
    gcTime: 60 * 60 * 1000,
  });

  useEffect(() => {
    if (mergedData) {
      setMergedInspections(mergedData);
      setSelectedIndex(mergedData.length - 1); 
    }
  }, [mergedData]);

  useEffect(() => {
    if (contentRef.current) {
      setContainerHeight(contentRef.current.scrollHeight);
    }
  }, [mergedInspections, selectedIndex]);

  if (recordsLoading || reportsLoading) return <Loader />;
  if (!assetId) return <p>No asset selected.</p>;
  if (recordsError || reportsError) return <p>Error loading inspection data.</p>;
  if (inspectionRecords.length === 0) return <EmptyRequestDisplay />;

  const timelineDates = mergedInspections.map((mi) => mi.inspectionDate);
  const selectedMerged = mergedInspections[selectedIndex];
  const totalPages = selectedMerged?.observations
    ? Math.ceil(selectedMerged.observations.length / PAGE_SIZE)
    : 0;
  const paginatedObservation = selectedMerged?.observations
    ? selectedMerged.observations.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)[0]
    : null;

  return (
    <div className="xa_asset_inspection_records--container">
      <div className="xa_asset_inspection_records">
        {mergedInspections.length > 0 ? (
          <HorizontalTimeline
            index={selectedIndex}
            indexClick={(index: number) => setSelectedIndex(index)}
            values={timelineDates}
            isKeyboardEnabled={true}
            isTouchEnabled={true}
          />
        ) : (
          <EmptyRequestDisplay />
        )}
        {selectedMerged && (
          <React.Fragment>
            <div className="xa_asset_inspection_records--data" 
            // style={{ height: containerHeight + "px" }}
            >
              <div ref={contentRef}>
              {Object.entries(selectedMerged)
                .filter(([key]) => key !== 'observations')
                .map(([key, value]) => {
                    let formattedValue: string;
                    switch (key) {
                    case 'totalCosts':
                        formattedValue = formatCurrency(value as number);
                        break;
                    case 'averageScore':
                        formattedValue = Number(value).toFixed(Number(value) === 0 ? 0 : 2);
                        break;
                    case 'signedOff':
                        formattedValue = capitalizeFirstChar(String(value));
                        break;
                    default:
                        formattedValue = String(value);
                    }
                    return (
                    <AttributeRow
                        name={(key)}
                        value={formattedValue}
                        key={key}
                    />
                    );
                })}
              </div>
            </div>
            <div className="xa_asset_inspection_records--footer" 
            // style={{ borderTop: totalPages > 1 && overlayOpen ? "none" : "0.1rem solid var(--xa-secondary)" }}
            >
              <span
                className={overlayOpen ? "xa-button action exit-state fixed-summary" : "xa-button action fixed-summary"}
                onClick={() => {
                  if (overlayOpen) {
                    setClosingOverlay(true);
                    setTimeout(() => {
                      setOverlayOpen(false);
                      setClosingOverlay(false);
                    }, 100);
                  } else {
                    setOverlayOpen(true);
                    setCurrentPage(1);
                  }
                }}
              >
                {overlayOpen ? "Exit" : "Summary"}
              </span>
            </div>
          </React.Fragment>
        )}
      </div>
      {overlayOpen && selectedMerged && (
        <div className={`xa_asset_inspection_report--modal-overlay ${closingOverlay ? "xa_asset_inspection_report--modal-overlay-exit" : ""}`}>
            <div className="xa_asset_inspection_report--modal-content">
            <div className="xa_asset_inspection_report--scrollable-content">
                {selectedMerged.observations && selectedMerged.observations.length > 0 ? (
                <>
                    {paginatedObservation ? (
                    Object.entries(paginatedObservation).map(([key, value]) => {
                      let formattedValue: string;
                      switch (key) {
                        case 'workCost':
                          formattedValue = formatCurrency(value as number);
                          break;
                        case 'priority':
                          formattedValue = value === "?" ? "N/A" : String(value);
                          break;
                        case 'comments':
                        case 'action':
                        case 'suggestedTreatment':
                          formattedValue = value === null ? "N/A" : String(value);
                          break;
                        default:
                          formattedValue = String(value);
                      }
                      return (
                        <AttributeRow
                          name={(key)}
                          value={formattedValue}
                          key={key}
                        />
                      );
                    })
                  ) : (
                    <AttributeRow name="Observations" value="None" />
                    )}
                </>
                ) : (
                <AttributeRow name="Observations" value="None" />
                )}
            </div>
            {totalPages > 1 && (
            <div className="xa_asset_inspection_report--pagination">
                <FaAngleLeft
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                style={{ cursor: currentPage === 1 ? "auto" : "pointer", opacity: currentPage === 1 ? "0.2" : "1" }}
                />
                <span style={{ fontSize: "1.2rem" }}>
                    Page <b>{currentPage}</b> of {totalPages}
                </span>
                <FaAngleRight
                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                style={{ cursor: currentPage === totalPages ? "auto" : "pointer", opacity: currentPage === totalPages ? "0.2" : "1" }}
                />
            </div>
            )}
            </div>
        </div>
        )}

    </div>
  );
};

export default InspectionsSummaryReport;