import React, { Fragment, useState, useEffect } from 'react';
import { HiChevronRight, HiChevronDown, HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import XALogoMark from 'assets/images/xa-logomark.webp';
import XALogoDark from 'assets/images/xa-logo-w.webp';
import './style.scss';
import { MdDashboard } from 'react-icons/md';
import { FaUserGear } from "react-icons/fa6";
import { TbReport } from "react-icons/tb";
import { get } from 'utils/request';
import { UserDetails } from 'models/UserDetails';
import { useSelector } from 'react-redux';

interface Props {
  displayModal: (title: string) => void;
  isExpanded: boolean | null;
  setIsExpanded: (value: boolean) => void;
  toggleNavBarX: () => void;
}

const NAVBAR_PRIMARY_MENU = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <MdDashboard />,
    children: [
      {
        title: 'Major asset group overview',
        path: '/'
      }
    ]
  }
];

const NavBar: React.FC<Props> = ({
  displayModal,
  isExpanded,
  setIsExpanded,
  toggleNavBarX
}) => {
  const [isSubmenuOpened, setSubmenuOpened] = useState<{ [k: string]: boolean }>({});
  const [navBarMenu, setNavBarMenu] = useState<typeof NAVBAR_PRIMARY_MENU>(NAVBAR_PRIMARY_MENU);
  const username = useSelector((state: { user: UserDetails }) => state.user.username);
  const location = useLocation();

  const ADMIN_PAGE_PATH = location.pathname.startsWith("/administration-toolkit")

  const isAdmin = async () => {
    const user = await get<UserDetails>(`users/${username}`);
    if (user.data.admin) {
      setNavBarMenu([
        ...navBarMenu,
        {
          title: 'Administration Toolkit',
          path: '/administration-toolkit',
          icon: <FaUserGear />,
          children: []
        },
        {
          title: 'Inspections Toolkit',
          path: '/inspections-toolkit',
          icon: <TbReport />,
          children: []
        },
      ]);
    }
  };

  useEffect(() => {
    const navbarExpanded = localStorage.getItem('navbarExpanded');
    const submenuState = localStorage.getItem('submenuState');
    setIsExpanded(navbarExpanded === 'true');
    if (submenuState) {
      setSubmenuOpened(JSON.parse(submenuState));
    }
    isAdmin();
  }, []);

  useEffect(() => {
    if (ADMIN_PAGE_PATH) {
      setSubmenuOpened(prev => ({ ...prev, Dashboard: false }));
    }
  }, [location.pathname, ADMIN_PAGE_PATH]);

  useEffect(() => {
    if (isExpanded !== null) {
      localStorage.setItem('navbarExpanded', isExpanded.toString());
      localStorage.setItem('submenuState', JSON.stringify(isSubmenuOpened));
    }
  }, [isSubmenuOpened, isExpanded]);

  const toggleSubmenu = (itemName: string) => {
    setSubmenuOpened(prev => ({
      ...prev,
      [itemName]: !prev[itemName]
    }));
  };

  const handlePrimaryMenuClick = (event: React.MouseEvent<HTMLElement>, title: string) => {
    event.preventDefault();
    if (title === 'Major asset group overview') {
      displayModal('Major asset group overview');
      setIsExpanded(false);
    }
    if (title === 'Inspections Toolkit') {
      displayModal('Inspections Toolkit');
      setIsExpanded(false);
    }
  };

  return (
    <div className={`xa-navbar${isExpanded ? ' expanded' : ' collapsed'}`}>
      {navBarMenu.map((item) => (
        <Fragment key={item.title}>
          <div className={`xa-navbar-item${isSubmenuOpened[item.title] ? ' submenu-opened' : ''}`} 
          data-tip={item.title} 
          data-for={item.title}>
            <NavLink
              to={item.path}
              onClick={(e) => {
                if (item.children.length > 0 && isExpanded) {
                  if (item.title === 'Dashboard' && ADMIN_PAGE_PATH) {
                    return;
                  }
                  e.preventDefault();
                  toggleSubmenu(item.title);
                }
              }}
              className={({ isActive }) => {
                if (item.path === '/' && ADMIN_PAGE_PATH) {
                  return '';
                }
                return isActive ? 'active' : '';
              }}
            >
              <span className="xa-navbar-item-icon">{item.icon}</span>
              <span className="xa-navbar-item-text">{item.title}</span>
              {isExpanded && item.children.length > 0 && !(item.title === 'Dashboard' && ADMIN_PAGE_PATH) && (
                <span className="xa-navbar-item-parent-icon">
                  {isSubmenuOpened[item.title] ? <HiChevronDown /> : <HiChevronRight />}
                </span>
              )}
            </NavLink>
            {(!ADMIN_PAGE_PATH || isExpanded) && (
              <div className="xa-navbar-submenu">
                {(!isExpanded && item.children.length > 0) &&
                  <div className="xa-navbar-submenu-title">{item.title}</div>
                }
                {item.children.map((subitem, index) => (
                  <NavLink 
                  to={subitem.path} 
                  key={index} 
                  onClick={(e) => handlePrimaryMenuClick(e, subitem.title)} className={({ isActive }) =>
                    isActive ? 'active' : ''}>
                    <span>{subitem.title}</span>
                  </NavLink>
                ))}
              </div>
            )}
          </div>
          {(!isExpanded && item.children.length === 0) && (
            <ReactTooltip
              id={item.title}
              place="right"
              type="dark"
              effect="solid"
              backgroundColor="var(--xa-black)"
              textColor="var(--xa-white)"
            />
          )}
          {(!isExpanded && item.title === 'Dashboard' && ADMIN_PAGE_PATH) && (
            <ReactTooltip
              id={item.title}
              place="right"
              type="dark"
              effect="solid"
              backgroundColor="var(--xa-black)"
              textColor="var(--xa-white)"
            />
          )}
        </Fragment>
      ))}
      <div className={`xa-navbar-logo ${!isExpanded && 'collapsed'}`}>
        <img src={isExpanded ? XALogoDark : XALogoMark} alt="XA Logo" />
        {isExpanded && (
          <>
            <span className="xa-navbar-logo-poweredby">powered by</span>
            <a href="https://xais.co.uk/" target="_blank" rel="noreferrer noopener">XAIS-PTS</a>
          </>
        )}
      </div>
      <div className="xa-navbar-toggle" onClick={toggleNavBarX}>
        <span className="xa-navbar-toggle-icon">
          {isExpanded ? <HiChevronDoubleLeft /> : <HiChevronDoubleRight />}
        </span>
        <span className="xa-navbar-toggle-text">Collapse</span>
      </div>
    </div>
  );
};

export default NavBar;
