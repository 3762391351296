import React, { Children, useEffect, useState } from 'react'
import Tree from 'rc-tree';
import { assetActions, AssetAttributesInfo, AssetElementsInfo, EyeViDto, AssetInspectionInfo } from 'actions/asset';

type AssetHubLegendProps = {
  data: any;
  modalDetails: any;
  setModalDetails: React.Dispatch<React.SetStateAction<any>>;
  infoTrigger: boolean;
  setInfoTrigger: React.Dispatch<React.SetStateAction<boolean>>
  setSpanInfoError: React.Dispatch<React.SetStateAction<boolean>>
  setWasAssetHubRefreshLoading: React.Dispatch<React.SetStateAction<boolean>>
  wasAssetHubRefreshedClicked: boolean,
  wasAssetHubRefreshLoading: boolean,
};

const AssetHubLegend: React.FC<AssetHubLegendProps> = ({data, modalDetails, setModalDetails, infoTrigger, setInfoTrigger, setSpanInfoError, wasAssetHubRefreshedClicked,wasAssetHubRefreshLoading, setWasAssetHubRefreshLoading }) => {
  const TREE_DATA = [
    {
      key: 'parent-asset',
      title: 'Bridge Asset( No. 620B)',
      children: [],
    },
  ];

  const [attributes, setAttributes] = useState<AssetAttributesInfo[]>([]);
  const [inspectionDates, setInspectionDates] = useState<AssetInspectionInfo[]>([]);
  const [elements, setElements] = useState<AssetElementsInfo[]>([]);
  const [showAttributes, setShowAttributes] = useState<boolean>(false);
  const [showElements, setShowElements] = useState<boolean>(false);
  const [treeData, setTreeData] = useState(TREE_DATA)
  const [assetId, setAssetId] = useState<number>(0);


  useEffect(() => {
    if (!showAttributes || data?.category !== 'asset' || !modalDetails?.id) {
      return;
    }

    const fetchAssetAttributes = async () => {
      try {
        const result = await assetActions().getAssetAttributes(modalDetails.id, wasAssetHubRefreshedClicked);
        if (wasAssetHubRefreshedClicked) setWasAssetHubRefreshLoading(false);
        setAttributes(result);

        const attributes = {
          key: '0-0-0',
          title: 'Attributes',
          children: result.map((item: any) => ({
            key: item.name,
            title: item.name,
            [item.name]: item.value,
            parent: 'Attributes'
          })),
        }

        const localTree = [...treeData] as any;
        if(localTree?.[0]?.children){
          localTree[0].children[0] = attributes;
        }

        setTreeData(localTree);
      } catch (error) {
        console.error('Failed to fetch asset attributes:', error);
      }
    };

    fetchAssetAttributes();
  }, [showAttributes, wasAssetHubRefreshLoading]);


  function generateRandomKey() {
    return Math.random().toString(36).substring(2, 15);
  }

  useEffect(() => {
    if (showElements && data?.category === 'asset' && modalDetails?.id) {
      assetActions().getAssetElements(modalDetails.id, wasAssetHubRefreshedClicked).then((result) => {
        const groupedData = result.reduce((acc: any, item) => {
          const existingEntry = acc.find((entry: any) => entry.title === `Span No ${item.spanNumber}`);

          const modifiedItem = {
            ...item,
            key: generateRandomKey(),
            title: item.name,
            parent: "Spans",

          };

          if (existingEntry) {
            existingEntry.children.push(modifiedItem);
          } else {
            acc.push({
              title: `Span No ${item.spanNumber}`,
              key: generateRandomKey(),
              children: [modifiedItem],
              number: item.spanNumber,
              isSpanNode: true
            });
          }

          return acc;
        }, []);

        const spans = {
          key: generateRandomKey(),
          title: 'Spans',
          children: groupedData
        }

        const localTree = [...treeData] as any;
        if(localTree?.[0]?.children){
          localTree[0].children[1] = spans;
        }

        setTreeData(localTree);
        setElements(result);
      });
    }
  }, [showElements, wasAssetHubRefreshLoading]);

  useEffect(() => {
    
   if(modalDetails) {
    assetActions().getAssetDetails(data.id, wasAssetHubRefreshedClicked).then((result: any) => {
      setShowAttributes(result.attributes || false)
      setShowElements(result.elements || false);
      const localTree = [...treeData];
      localTree[0].title = `Asset No. ${result.number}`
      setTreeData(localTree)
    });

    };
  }, [modalDetails, wasAssetHubRefreshLoading])

 
  useEffect(() => {
    if (!modalDetails?.id) return;
    
    const fetchInspectionData = async () => {
      try {
        const result = await assetActions().getAssetInspectionDetails(modalDetails.id, wasAssetHubRefreshedClicked);
        if (wasAssetHubRefreshedClicked) setWasAssetHubRefreshLoading(false);
        setInspectionDates([result])
        if (!result) return;

        const inspectionGroups: any = {};
        result.forEach((item: any) => {
          if (!inspectionGroups[item.inspectionType]) {
            inspectionGroups[item.inspectionType] = [];
          }
        });

        const inspectionTypes = {
          key: '0-0-1',
          title: Object.keys(inspectionGroups).length > 1 ? 'Inspections' : 'Inspection',
          children: Object.entries(inspectionGroups).map(([type, regimes]: any) => ({
            key: type.toLowerCase().replace(/\s/g, '-'),
            title: type,
            parent: Object.keys(inspectionGroups).length > 1 ? 'Inspections' : 'Inspection', 
            children: regimes.map((regime: any) => ({
              key: `regime-${regime.frequencyName.toLowerCase().replace(/\s/g, '-')}`,
              title: `${regime.frequencyName} Inspection`,
              parent: type,  
              children: [],
            })),
          })),
        };
        
        const localTree = [...treeData] as any;
        if(localTree?.[0]?.children){
          localTree[0].children[1] = inspectionTypes;
        }
        setTreeData(localTree);
      } catch (error) {
        console.error('Failed to fetch survey data:', error);
      }
    };
    fetchInspectionData();
  }, [modalDetails, wasAssetHubRefreshLoading]);


  useEffect(() => {
    if (modalDetails?.id) {
      setAssetId(modalDetails.id);
    }
  }, [modalDetails]);

  const handleNodeClick = (selectedKeys: any, e: any) => {
    setSpanInfoError(false)
    const selectedNode = e.selectedNodes?.[0];
    if (!selectedNode) return;
    
    if(!selectedNode?.children && selectedNode?.parent){ 
      if(selectedNode?.parent === 'Attributes'){
        setModalDetails({...selectedNode,[selectedKeys]: selectedNode?.[selectedKeys?.[0]]})
      }else{
        assetActions().getElementAttributes(selectedNode.assetId, selectedNode.spanNumber, selectedNode.id, wasAssetHubRefreshedClicked).then((result: any)=> {
        const combinedObject = {} as any;

        result.forEach((item: any) => {
          if(item.name && item.value){
            combinedObject[item.name] = (item.value)
          }
        })
          setModalDetails(combinedObject);
        }).catch(() => {
          setSpanInfoError(true)
          setModalDetails(null)
        })
      }
    }
    else if(selectedNode?.title === 'Attributes') { 
        assetActions().getAssetAttributes(data.id, wasAssetHubRefreshedClicked).then((result: any) => {
          setModalDetails(result);
        }).catch((err) => console.log(err))
      }
      else if(selectedNode?.parent === 'Inspections' || selectedNode?.parent === 'Inspection'){
        
        assetActions()
        .getAssetInspectionDetails(assetId, wasAssetHubRefreshedClicked)
        .then((result: any) => {
          const inspectionDetail = result.find(
            (item: any) => item.inspectionType.toLowerCase() === selectedNode.title.toLowerCase()
          );
          if (inspectionDetail) {
            setModalDetails(inspectionDetail);
          } else {
            setModalDetails({
              message: 'No details available for selected inspection.',
            });
          } 
      })
  
      }else if (selectedNode?.title === 'Inspections' || selectedNode?.title === 'Inspection') {
        assetActions()
          .getAssetInspectionDetails(assetId, wasAssetHubRefreshedClicked)
          .then((result: any) => {
            if (Array.isArray(result) && result.length > 0) {
              const lastInspectionDate = result.reduce(
                (latest, item) => (item.lastInspectionDate > latest ? item.lastInspectionDate : latest),
                '0000-00-00'
              );
    
              const nextInspectionDate = result.reduce(
                (earliest, item) => (item.nextInspectionDate < earliest ? item.nextInspectionDate : earliest),
                '9999-99-99'
              );
    
              setModalDetails({
                LastInspectionPerformed: lastInspectionDate !== '0000-00-00' ? lastInspectionDate : 'N/A',
                NextInspectionPlanned: nextInspectionDate !== '9999-99-99' ? nextInspectionDate : 'N/A'
              });
            } else {
              setModalDetails({
                lastInspectionDate: 'N/A',
                nextInspectionDate: 'N/A'
              });
            }

    })
    .catch((error: any) => {
      console.error('Failed to fetch inspection details:', error);
    });
    }
    else if(selectedNode?.isSpanNode){
      assetActions().getSpanInfo(data.id, selectedNode?.number).then((result: any)=> {
      setModalDetails(result);
    })
    }else{
      setInfoTrigger(!infoTrigger)
    }
  };

  return <div className='xa_asset_hub--legend_container'>
    <Tree
      showLine
      selectable
      defaultExpandParent={true}
      autoExpandParent={true}
      treeData={treeData}
      showIcon={false}
      onSelect={handleNodeClick}
      defaultExpandedKeys={["parent-asset"]}
    />
  </div>
}

export default AssetHubLegend;
