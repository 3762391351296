export const capitalizeFirstChar = (str: string): string => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};


export const formatCurrency = (value: number | string): string => {
  const numberValue = Number(value);
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: numberValue % 1 !== 0 ? 2 : 0,
    maximumFractionDigits: 2,
  }).format(numberValue);
};
